<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-12">
                <label>Colaborador</label>
                <v-select v-model="home_office.user_id" :options="users" :reduce="(users) => users.id" :label="'name'"></v-select>
              </div>
              <div class="col-md-12 mt-1">
                <label>Status</label>
                <select v-model="home_office.status" class="form-control">
                  <option :value="1">Ativo</option>
                  <option :value="0">Inativo</option>
                </select>
              </div>
              <div class="col-md-5 mt-1">
                <label>Data do Início da jornada</label>
                <input v-model="home_office.start_date" id="name" class="form-control" type="date" max="9999-12-31" />
              </div>
              <div class="col-md-5 mt-1">
                <label>Como será realizado o expediente?</label>
                <select v-model="home_office.home_office" class="form-control">
                  <option :value="0">Presencial</option>
                  <option :value="1">Home Office</option>
                  <option :value="2">Férias</option>
                  <option :value="3">Folga</option>
                  <option :value="4">Atestado</option>
                </select>
              </div>
              <div class="col-md-2 mt-3" v-if="!home_office.id">
                <a class="btn btn-add" @click="add">
                  Adicionar
                  <i class="fa fa-plus" />
                </a>
              </div>

              <div class="col-md-12 mt-1" v-if="!home_office.id">
                <table class="table table-bordered" style="width: 100%">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 25%">Data</th>
                      <th scope="col" style="width: 25%">Dia da Semana</th>
                      <th scope="col" style="width: 25%">Expediente</th>
                      <th scope="col" style="width: 25%">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(entry, index) in home_office.dates" :key="index">
                      <td>
                        {{ formatarData(entry.date) }}
                      </td>
                      <td>
                        {{ formatarDiaSemana(entry.date) }}
                      </td>
                      <td>
                        {{ homeOfficeLabels[entry.home_office] }}
                      </td>
                      <td>
                        <button class="btn btn-crud delete" @click="remover(index)">
                          <i class="far fa-trash-alt" data-toggle="tooltip" title="Excluir"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-md-12 text-right">
                <hr />
                <router-link to="/home_offices" class="btn btn-back mr-1">
                  <i class="fa fa-arrow-left" />
                  Voltar
                </router-link>
                <a class="btn btn-add" @click="save">
                  Salvar
                  <i class="fa fa-save" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      home_office: {
        start_date: "",
        home_office: "",
        status: 1,
        dates: [],
        homes: [],
      },
      users: [],
      homeOfficeLabels: {
        0: "Presencial",
        1: "Home Office",
        2: "Férias",
        3: "Folga",
        4: "Atestado",
      },
    };
  },
  methods: {
    formatarData(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatarDiaSemana(date) {
      const diasSemana = [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ];
      const data = new Date(date);
      const diaSemana = data.getUTCDay();
      return diasSemana[diaSemana];
    },
    add() {
      if (this.home_office.start_date === "") {
        this.$message(null, "Você precisa informar uma data", "error");
        return;
      }
      if (this.home_office.home_office === "") {
        this.$message(null, "Você precisa informar um status", "error");
        return;
      }

      const exist = this.home_office.dates.find(
        (entry) => entry.date === this.home_office.start_date
      );
      if (exist) {
        this.$message(null, "Você já adicionou essa data", "error");
        return;
      }

      const newEntry = {
        date: this.home_office.start_date,
        home_office: this.home_office.home_office,
      };

      this.home_office.dates.push(newEntry);

      // Adiciona 1 dia normalmente, mas se for sexta-feira, adiciona 3 dias
      let currentDate = new Date(this.home_office.start_date);
      const dayOfWeek = currentDate.getUTCDay();

      // Verifica se é sexta-feira (5). Se for, pula para segunda-feira
      currentDate.setDate(currentDate.getDate() + (dayOfWeek === 5 ? 3 : 1));
      this.home_office.start_date = currentDate.toISOString().slice(0, 10);
    },
    remover(index) {
      this.home_office.dates.splice(index, 1);
    },
    save() {
      let api = this.$store.state.api + "home_offices";
      if (this.home_office.id) {
        api += "/" + this.home_office.id;
        this.home_office._method = "PUT";
      }
      axios
        .post(api, this.home_office)
        .then(() => {
          this.$message("Sucesso", "Informações guardadas com sucesso", "success");
          this.$router.push("/home_offices");
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },
    getHomeOffice(id) {
      const api = this.$store.state.api + "home_offices/" + id;
      axios
        .get(api)
        .then((response) => {
          this.home_office = response.data.data[0];
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },
    getUsers() {
      const api = this.$store.state.api + "users/";
      axios
        .get(api)
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    let id = this.$route.params.id;
    this.getUsers();
    if (id) {
      this.getHomeOffice(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
