<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-12 mb-1">
                <label>Título</label>
                <input
                  v-model="knowledge_base.title"
                  class="form-control"
                  type="text"
                  disabled
                />
              </div>
              <div class="col-md-12 mb-1">
                <label>Descrição:</label>
                <ckeditor
                  v-model="knowledge_base.description"
                  :config="editorConfig"
                  :editor="editor"
                  disabled
                />
              </div>
              <div class="col-md-12">
                <table v-if="knowledge_base.file" class="table table-bordered" style="width: 100%"> <!-- Alteração: Adicionada a condição v-if na tabela -->
                  <thead>
                    <tr>
                      <th scope="col" style="width: 70%">Nome do Arquivo</th>
                      <th scope="col" style="width: 30%">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-if="knowledge_base.file"> <!-- Alteração: Adicionada a condição v-if -->
                      <td>{{ knowledge_base.file }}</td>
                      <td>
                        <a
                          class="btn btn-add mr-1"
                          :href="knowledge_base.file_url"
                          target="_blank"
                        >
                          <i class="fas fa-download"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12 text-right">
                <hr />
                <router-link to="/knowledge_bases" class="btn btn-back mr-1">
                  <i class="fa fa-arrow-left" />
                  Voltar
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      knowledge_base: {
        title: "",
        description: "",
        id: null,
        file: "", // Supondo que o nome do arquivo esteja disponível
      },
      editor: ClassicEditor,
      editorConfig: {
        width: "800px",
        height: "400px",
      },
    };
  },
  methods: {
    getKnowledgeBase(id) {
      const api = this.$store.state.api + "knowledge_bases/" + id;

      axios
        .get(api)
        .then((response) => {
          this.knowledge_base = response.data.data[0];
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },
    setView() {
      this.id = this.$route.params.id;
    },
  },
  mounted() {
    const id = this.$route.params.id;

    this.setView();
    if (id) {
      this.getKnowledgeBase(id);
    }
  },
};
</script>
